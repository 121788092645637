
export const getEnvironment = (): boolean => {
  let ua = navigator.userAgent.toLowerCase();
  return /MicroMessenger/i.test(ua)
}

function getIsWxClient () {
  var ua = navigator.userAgent.toLowerCase();
  let environment = ua.match(/MicroMessenger/i)?.[0]
  if (environment == "micromessenger") {
      return true;	//是微信环境
  } else {
      return false;	//不是微信环境
  }
};

// 去掉左右空格
export const trim = (s: string) => {
  return s.replace(/\s*/g, '');

}