
let userInfo: object = {}
let pageScroll: number = 0;
let inputSearchValue: string = ''
const user = localStorage.getItem("userInfo")
const scroll = localStorage.getItem('scroll')
const searchValue = sessionStorage.getItem('inputSearchValue')
try {
  if (user) {
    userInfo = JSON.parse(user)
  }
} catch (e) {}

try {
  if (scroll) {
    pageScroll = JSON.parse(scroll)
  }
} catch (e) {}
try {
  if (searchValue) {
    inputSearchValue = JSON.parse(searchValue)
  }
} catch (e) {}


export default {
  userInfo,
  pageScroll,
  inputSearchValue
}
