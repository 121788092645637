/*
 * @Author: your name
 * @Date: 2021-03-22 22:20:01
 * @LastEditTime: 2021-04-13 21:50:21
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \fodu-h5\src\pages\index\components\Search\index.tsx
 */
import { defineComponent, PropType, onMounted, ref, toRefs, Ref, onUpdated } from "vue";
import style from './index.module.less'

export default defineComponent({
  name: 'SearchList',
  props: {
    inputValue: {
      type: String,
      required: true,
    },
    list: {
      type: Array as PropType<string[]>,
      required: true
    },
    handleClickItem: {
      type: Function as PropType<(T: string) => void>,
      required: true,
    },
    handleHiddenSearchList: {
      type: Function as PropType<() => void>,
      required: true
    },
    handleInput: {
      type: Function as PropType<(V: string) => void>,
      required: true
    }
  },
  setup(props) {
    const { list, inputValue, handleClickItem, handleInput, handleHiddenSearchList } = toRefs(props)
    const windowHeight: Ref<number> = ref(0);
    const searchInput: Ref<HTMLInputElement | null> = ref(null)
    onMounted(() => {
      windowHeight.value = document.documentElement.clientHeight
    })
    onUpdated(() => {
      searchInput?.value?.focus()
    })
    const handleSearhchInput = (e: Event) => {
      const targetValue = (e.target as HTMLInputElement)?.value
      handleInput.value(targetValue)
    }
    const handleKeyup = (e: KeyboardEvent) => {
      if (e?.key === 'Enter') {
        handleClickItem.value(inputValue.value)
      }
    }
    const handleBtnClick = () => {
      handleClickItem.value(inputValue.value)
    }
    return () => (
      <div class={style.container}>
        <div class={style.top}>
          <i onClick={() => handleHiddenSearchList.value()} class={`iconfont ${style.returnIcon}`}>&#xe61e;</i>
          <form class={style.searchForm} action="javascript:;">
            <input
              ref={searchInput}
              type="search"
              onInput={e => handleSearhchInput(e)}
              maxlength={120}
              /* onChange={e => handleSearhchInput(e)} */
              onKeyup={handleKeyup}
              value={inputValue.value}
              class={style.input}
            />
          </form>
          <div onClick={handleBtnClick} class={style.btn}>搜索</div>
        </div>
        <div style={{height: (windowHeight.value - 65) + 'px'}} class={style.searchList}>
            {
              list.value.map((item, index) => {
                return (
                  <div
                    key={index}
                    class={style.item}
                    onClick={() => {
                      inputValue.value = item
                      handleClickItem.value(item)
                    }}
                  >
                    <i class={`iconfont ${style.searchIcon}`}>&#xe603;</i>
                    <div class={style.title}>{item}</div>
                    <i class={`iconfont ${style.skip}`}>&#xe648;</i>
                  </div>
                )
              })
            }
        </div>
      </div>
    )
  }
})

