/*
 * @Author: your name
 * @Date: 2021-04-26 21:06:11
 * @LastEditTime: 2021-05-04 21:30:18
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \fodu-h5\src\utils\weather.ts
 */
import { jsonp } from 'vue-jsonp'
import axios from 'axios'
import { ResData } from '@/model/model'
import { base64Analysis } from '@/utils/base64Analysis'

interface MapData {
  status: number;
  message: string;
  result: {
    ad_info: {
      adcode: number;
      city: string;
      district: string;
      nation: string;
      province: string;
    }
  }
}

export interface weatherInfo {
  adcode: string;
  city: string;
  temperature: string;
  weather: string;
}

// 请求天气接口
export const getWeather = async(): Promise<weatherInfo> => {
  /* let data = {
    adcode: '',
    city: '',
    temperature: '',
    weather: '',
  } */
  const ip = returnCitySN["cip"]
  const key = "KSWBZ-OLZ6J-FQCFO-KQSYV-5VM43-JBBVV";
  const url = "https://apis.map.qq.com/ws/location/v1/ip/"
  let data = {
    adcode: '',
    city: '',
    temperature: '',
    weather: '',
  }
  await jsonp(url, {
    key,
    ip,
    output: "jsonp",
  }).then( async (res: MapData) => {
    const { adcode } = res.result.ad_info
    await axios.get(`https://api.fodu.com/v1/new/new_wether?city=${adcode}`).then((res: ResData) => {
      data =  JSON.parse(base64Analysis(res.data.result)).lives[0]
      // data =  base64Analysis(res.data.result).lives[0]
    })
    /* await axios.get(`https://api.fodu.com/v1/new/weather`).then((res: ResData) => {
      data =  base64Analysis(res.data.result).lives[0]
    }) */
  })
  return data
}