/*
 * @Author: your name
 * @Date: 2021-03-21 11:01:57
 * @LastEditTime: 2021-03-21 11:28:33
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \vue3tstemplate\src\router\index.ts
 */
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Index from '../pages/index'
import store from '../store'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Index',
    component: Index,
    meta: {
      keepAlive: true
    }
  }, {
    path: '/login',
    name: 'Login',
    component: () => import('@/pages/login')
  }, {
    path: '/externalLink',
    name: 'externalLink',
    component: () => import('@/pages/externalLink')
  }, {
    path: '/personal',
    name: 'personal',
    component: () => import('@/pages/personal')
  }, {
    path: '/bindPhone',
    name: 'BindPhone',
    component: () => import('@/pages/bindPhone')
  }, {
    path: '/account',
    name: 'Account',
    component: () => import('@/pages/account')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  var sUserAgent = navigator.userAgent.toLowerCase();
  if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(sUserAgent)) {
    if (to.name === 'personal' || to.name === 'BindPhone') {
      if (store.getters.getUserInfo?.is_login) {
        next();
      } else {
        next({ path: '/login' })
      }
    } else {
      next();
    }
  } else {
    window.location.href = 'https://fodu.com/#/index'
  }
})

export default router
