/*
 * @Author: your name
 * @Date: 2021-04-03 21:50:08
 * @LastEditTime: 2021-04-03 22:14:13
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \fodu-h5\src\model\model.ts
 */
export interface ResData {
  appid?: string,
  total?: string,
  list?: object[],
  provide_num?: number;
  [propName: string]: any;
  [propName: number]: any;
}
import { weatherInfo } from '@/utils/weather'

export const isString = (props: any): props is string =>
  typeof props === 'string'

export const isResData = (props: any): props is ResData =>
  typeof ((props as ResData)['list'] || (props as ResData)['provide_num']) !== 'undefined'

export const isWXShare = (props: any): props is ResData =>
typeof (props as ResData)['appid'] !== 'undefined'

export const isStringNum = (props: any): props is [] =>
  props instanceof Array

export const isNumber = (props: any): props is number => {
  return typeof props === "number";
}

export const isWeatherInfo = (props: any): props is weatherInfo => {
  return typeof (props as weatherInfo)['weather'] !== 'undefined'
}
