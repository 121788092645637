/*
 * @Author: your name
 * @Date: 2021-04-12 21:02:44
 * @LastEditTime: 2021-04-14 21:27:12
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \fodu-h5\src\pages\index\components\List\components\NewList\index.tsx
 */
import { defineComponent, PropType, toRefs } from "@vue/runtime-core";

import style from './index.module.less'
interface ContentItem {
  a_url: string,
  goods?: object[],
  htmlTitle?: string
  img_url?: string[],
  source: string,
  description: string,
  tags: number,
  title: string,
  reddescription?: string;
}

export default defineComponent({
  props: {
    contentItem: {
      type: Object as PropType<ContentItem>,
      required: true
    },
    highlight: {
      type: String,
      required: true
    },
    reddescription: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const { contentItem, highlight, reddescription } = toRefs(props)
    const { img_url, source, description, htmlTitle } = contentItem.value
    return () => {
      return (
        <>
          {
            (img_url && img_url?.length <= 2) ?
            <div class={style.oneImgItem}>
              <van-image src={img_url[0]} lazy-load class={style.image} />
              {/* <img v-lazy={img_url[0]} class={style.image} onError={handleErrorImg} /> */}
              <div class={style.content}>
                <div class={style.title} v-html={highlight.value ? highlight.value : htmlTitle}></div>
                <div
                  class={style.value}
                  v-html={reddescription.value ? reddescription.value : description}
                ></div>
                {/* <div class={style.value}>{description}</div> */}
                <div class={style.webTitle}>{source}</div>
              </div>
            </div> :
            <div class={style.moreImgItem}>
              <div class={style.title} v-html={highlight.value ? highlight.value : htmlTitle}></div>
              { (img_url && img_url?.length >= 3) ?
              <div class={style.imgList}>
                {
                  img_url && img_url.map(item => {
                    return (
                      <img v-lazy={item} class={style.img} />
                    )
                  })
                }
              </div> :
              <div
                class={style.value}
                v-html={reddescription.value ? reddescription.value : description}
              ></div>
              }
              <div class={style.webTitle}>{source}</div>
            </div>
          }
        </>
      )
    }
  }
})
