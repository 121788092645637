/*
 * @Author: your name
 * @Date: 2021-04-06 21:21:14
 * @LastEditTime: 2021-04-08 21:07:32
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \fodu-h5\src\pages\index\components\Header\index.tsx
 */
import { defineComponent, PropType, onMounted, toRefs, computed, ref } from "@vue/runtime-core";
import axios from 'axios'
import { useStore } from "vuex";
import { StateProps } from '@/store'
import style from './index.module.less'
import { getWeather } from '@/utils/weather'
import { isWeatherInfo } from '@/model/model'
import { weatherInfo } from '@/utils/weather'

export default defineComponent({
  name: 'Header',
  props: {
    handleClickUserInfo: {
      type: Function as PropType<() => void>,
      required: true
    }
  },
  setup(props) {
    const { handleClickUserInfo } = toRefs(props)
    const store = useStore<StateProps>()
    const userInfo = computed(() => store.state.userInfo)
    const weather = ref<weatherInfo>({
      adcode: '',
      city: '',
      temperature: '',
      weather: '',
    })
    onMounted(() => {
      getWeather().then(res => {
        if (isWeatherInfo(res)) {
          weather.value = res
        }
      })
    })
    // 跳转到日历里面
    const handleToCalendar = () => {
      location.href="http://www.12345677.com/foli/"
    }
    return () => {
      return (
        <div class={style.container}>
          {
            userInfo?.value?.header ?
            <img onClick={() => handleClickUserInfo.value()} src={userInfo?.value?.header} class={style.userImg} /> :
            <i onClick={() => handleClickUserInfo.value()} class={`iconfont ${style.userInfo}`}>&#xe620;</i>
          }
          <div class={style.weather}>
            <svg class={`${style.weatherIcon} icon`} aria-hidden="true">
              <use xlinkHref="#icontianqi"></use>
            </svg>
            <span class={style.city}>{weather?.value?.city}</span>
            <span class={style.temperature}>{weather?.value?.temperature}℃</span>
          </div>
          <i onClick={handleToCalendar} class={`iconfont ${style.calendar}`}>&#xe667;</i>
        </div>
      )
    }
  }
})