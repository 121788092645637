/*
 * @Author: your name
 * @Date: 2021-04-03 15:02:05
 * @LastEditTime: 2021-04-03 15:02:05
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \fodu-h5\src\services\codeConfig.ts
 */
interface codeConfig {
  [index: number]: string
}

export const config: codeConfig = {
  200: '请求成功',
  4000: '请求错误',
  4001: 'token失效，请重新获取token',
  4002: '没有权限',
  4003: '参数不正确',
  4004: '登录失败',
  4005: '账号不存在',
  4006: '账号密码错误',
  4007: '用户被禁用',
  4008: '两次密码不一致',
  4009: '账号已存在',
  4010: '手机号已存在',
  4011: '邮箱已存在',
  4012: '数据不存在',
  4013: '密码不能为空',
  4014: '昵称已存在',
  4015: '菜单名称已存在',
  4016: '菜单Code已存在',
  4017: '角色名已存在',
  4018: '角色Code已存在',
  4019: '权限名称已存在',
  4020: '权限Code已存在',
  4021: '图片只支持jpg格式',
  4022: '图片不能超过512K',
  4023: '文章标题已存在',
  4024: '手机号不能为空',
  4025: '短信发送失败',
  4026: '字段不存在',
  4027: '验证码不正确',
  4030: '绑定失败，请稍后再试',
}