// rem布局
let deviceWidth;
setHtmlFontSize();
if (window.addEventListener) {
    window.addEventListener('resize', function () {
        setHtmlFontSize()
    }, false)
}
export function setHtmlFontSize() {
  //750是设计稿的宽度，当大于750时采用750宽度，比例也是除以7.5
  deviceWidth = document.documentElement.clientWidth > 750 ? 750 : document.documentElement.clientWidth;
  document.getElementsByTagName('html')[0].style.cssText = 'font-size:' + deviceWidth / 7.5 + 'px !important'
}