/*
 * @Author: your name
 * @Date: 2021-03-21 11:27:07
 * @LastEditTime: 2021-05-05 11:38:09
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \vue3tstemplate\src\pages\index\index.tsx
 */
import { defineComponent, ref, reactive, onMounted, onUpdated, computed, Ref, onUnmounted, onActivated } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

import SearchList from './components/SearchList'
import Header from './components/Header'
import Search from './components/Search'
import IFrame from './components/IFrame'
import List, { ListProps } from './components/List'
import NoMoreImg from '@/components/NoMoreImg'
import ToTop from '@/components/ToTop'
import LoadingImg from '@/components/LoadingImg'
import { fetchIndexList, fetchSuggest, fetchWebSite } from '@/services/index'
import { isResData, isStringNum } from '@/model/model'
import { getScrollTop } from '../../utils/windowScroll'
import { StateProps } from '@/store'
import { share } from '@/utils/share'
import { trim } from '@/utils/utils'
import './index.module.less'
import { Toast } from 'vant'

export default defineComponent({
  name: 'Index',
  components: {
    Search,
    IFrame,
    ToTop,
    NoMoreImg,
    LoadingImg,
  },
  setup(props) {
    const router = useRouter()
    const store = useStore<StateProps>()
    const userInfo = computed(() => store.state.userInfo)
    const inputSearchValue = computed(() => store.state.inputSearchValue)
    const finished = ref(false)
    const isShowSearchList = ref(false) // 是否展示搜索列表
    const pageIndex = ref(1) //请求分页
    const inputValue = ref(''); // 用户搜索的内容
    const totals: Ref<number> = ref(0) // 总数
    const isLoading: Ref<boolean> = ref(false) // 下拉状态
    const statisData: Ref<object> = ref({}) // 词条和搜索人数
    const loadingList: Ref<boolean> = ref(false) // 当前列表请求是否完成
    const loadingImg: Ref<boolean> = ref(false)
    const state = reactive({
      indexList: [],
      searchList: [],
      isPageScroll: true,
      loading: false,
      pageScroll: 0,
      isIframe: false,
      iframeUrl: '',
    })
    onMounted(() => {
      window.addEventListener('scroll', pageScroll)
      share();
      if (window.history) {
        isShowSearchList.value = false
        history.pushState(null, '', '/');
        window.addEventListener('popstate', backChange, false);
      }
      inputValue.value =  inputSearchValue.value
      if (inputSearchValue?.value?.length >= 1) {
        fetch({ keyword: inputSearchValue?.value, tags: 2 })
      } else {
        fetch({ keyword: '', tags: 1 })
      }
      // Toast.fail('1')
    })
    onActivated(() => {
      // Toast.fail('2')
      /* pageIndex.value = 1;
      fetch({
        keyword: inputValue.value,
        tags: inputValue.value.length >= 1 ? 2 : 1,
      }) */
    })
    const backChange = () => {
      if (isShowSearchList.value) {
        history.pushState(null, '', '/');
        store.commit({
          type: 'closeModel',
        })
        state.isIframe = false
        isShowSearchList.value = false
      }
    }
    const handlePageScroll = () => {
      if (state.isPageScroll) {
        state.isPageScroll = false
        pageIndex.value = pageIndex.value + 1
        state.loading = true
        setTimeout(async () => {
          await fetch({
            keyword: inputValue.value ? inputValue.value : '',
            tags: inputValue.value ? 2 : 1,
            status: 2,
          })
          state.loading = false
          state.isPageScroll = true
        }, 1000)
      }
    }
    const handleClickSearch = () => {
      store.commit({
        type: 'openModel',
        pageScroll: state.pageScroll
      })
      isShowSearchList.value = true
    }
    const handleHiddenSearchList = () => {
      // document.getElementsByTagName('html')[0].className = 'hidden'
      store.commit({
        type: 'closeModel',
      })
      isShowSearchList.value = false
    }
    // 跳转到登录页面或者用户已经登录跳转到个人信息页面
    const handleClickUserInfo = () => {
      if (userInfo?.value?.m_id) {
        router.push({
          path: '/personal'
        })
      } else {
        router.push({
          path: '/login'
        })
      }
    }
    // 首页请求
    const fetch = ({ keyword='', tags=1, status=1 }) => {
      if (status === 1) {
        loadingImg.value = true
      }
      fetchIndexList({ keyword: trim(keyword), tags, pages: pageIndex.value }).then((res) => {
        if (isResData(res)) {
          const { total, list } = res
          totals.value = Number(total)
          loadingList.value = true
          let newList: [] = JSON.parse(JSON.stringify(list))
          newList.map((item: any) => {
            if (item._source.img_url && item._source.img_url.length > 0) {
              item._source.img_url = item._source.img_url.slice(0, item._source.img_url.length-1)
              item._source.img_url = item._source.img_url.split(',');
              if (item._source.img_url.length > 2) {
                item._source.img_url = item._source.img_url.slice(1, 4)
              } else {
                item._source.img_url = item._source.img_url.slice(0, 3)
              }
            }
            item._source.htmlTitle = item._source.title
            if (item?._source?.goods) {
              item._source.goods = JSON.parse(item._source.goods)
            }
            if (item?.highlight?.description?.length > 1) {
              let reddescription = ''
              for (let i = 0; i < item?.highlight?.description?.length; i ++) {
                reddescription+= item.highlight.description[i]
              }
              item.highlight.reddescription = reddescription
            }
            /* if (inputValue.value.length >= 1) {
              let title = inputValue.value
              for(let i = 0; i < title.length; i ++) {
                let T = inputValue.value[i]
                item._source.htmlTitle = item._source.htmlTitle.replace(new RegExp(`[${T}]`,'g'), `<span class="redTitle">${T}</span>`)
              }
            } */
          })
          console.log(newList)
          if (status === 1) {
            state.indexList = newList
          } else if (status === 2) {
            state.indexList = state.indexList.concat(newList)
          }
          if (state.indexList.length >= Number(total)) {
            finished.value = true
          } else {
            finished.value = false
          }
          setTimeout(() => {
            loadingImg.value = false
          }, 500)
        }
      })
      fetchWebSite({ keyword: trim(keyword) }).then(res => {
        if (isResData(res)) {
          statisData.value = res
        }
      })
    }

    // 用户搜索内容
    const handleInput = (value: string) => {
      inputValue.value = value
      if (value.length == 0) {
        state.searchList = [];
        return;
      }
      fetchSuggest({
        keyword: value
      }).then(res => {
        if (isStringNum(res)) {
          state.searchList = res
        }
      })
    }
    // 用户点击搜索内容
    const handleClickItem = (keyword: string) => {
      pageIndex.value = 1;
      fetch({
        keyword,
        tags: keyword.length >= 1 ? 2 : 1,
      })
      store.commit({
        type: 'closeModel',
      })
      store.commit({
        type: 'changeInputSearchValue',
        inputSearchValue: keyword
      })
      inputValue.value = keyword
      isShowSearchList.value = false
    }
    // 监听用户滚动
    const pageScroll = () => {
      state.pageScroll = getScrollTop()
    }
    // 监听用户点击列表
    const handleClickListItem = (url: string, title: string) => {
      /* store.commit({
        type: 'openModel',
        pageScroll: state.pageScroll
      })
      state.iframeUrl = url
      state.isIframe = true */
      router.push({
        path: '/externalLink',
        query: {
          // iframeUrl: `http://bk.syusb.com/bk/${url}.html`,
          iframeUrl: url,
          title
        }
      })
    }

    const onRefresh = async() => {
      isLoading.value = true
      pageIndex.value = 1;
      await fetch({
        keyword: inputValue.value,
        tags: inputValue.value.length >= 1 ? 2 : 1,
      })
      setTimeout(() => {
        isLoading.value = false
      }, 700)
    }

    return () => {
      return (
        <div class="container">
          <van-pull-refresh
            v-model={isLoading.value}
            success-text="刷新成功"
            onRefresh={onRefresh}
          >
            <Header handleClickUserInfo={handleClickUserInfo} />
            <Search
              statisData={statisData.value}
              inputValue={inputValue.value}
              pageScroll={state.pageScroll}
              handleClickSearch={handleClickSearch}
            />
            {
              isShowSearchList.value &&
              <SearchList
                list={state.searchList}
                inputValue={inputValue.value}
                handleClickItem={handleClickItem}
                handleHiddenSearchList={handleHiddenSearchList}
                handleInput={handleInput}
              />
            }
          {/*  <SearchList
              v-show={isShowSearchList.value}
              list={state.searchList}
              inputValue={inputValue.value}
              handleClickItem={handleClickItem}
              handleHiddenSearchList={handleHiddenSearchList}
              handleInput={handleInput}
            /> */}
            { loadingImg.value && <LoadingImg /> }
            {
              loadingList.value &&
              <>
                {
                  totals.value > 0 ?
                  <List
                    loading={state.loading}
                    finished={finished.value}
                    list={state.indexList}
                    handleClickListItem={handleClickListItem}
                    handlePageScroll={handlePageScroll}
                    inputValue={inputValue.value}
                  /> :
                  <NoMoreImg />
                }
              </>
            }
            {
              state.isIframe && <IFrame iframeUrl={state.iframeUrl} />
            }
            <ToTop v-show={state.pageScroll > 200 } />
          </van-pull-refresh>
        </div>
      )
    }
  }
})