/*
 * @Author: your name
 * @Date: 2021-03-24 21:45:14
 * @LastEditTime: 2021-04-05 21:50:40
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \fodu-h5\src\pages\index\components\Search\index.tsx
 */
import { defineComponent, PropType, toRefs } from "@vue/runtime-core";
import style from './index.module.less'
import logo from '@/assets/image/index/logo.jpg'

interface statisProps {
  include_num?: number;
  provide_num?: number;
}

export default defineComponent({
  props: {
    statisData: {
      type: Object as PropType<statisProps>,
      required: true
    },
    pageScroll: {
      type: Number,
      required: true
    },
    inputValue: {
      type: String,
      required: true
    },
    handleClickSearch: {
      type: Function as PropType<() => void>,
      required: true
    }
  },

  setup(props) {
    return () => {
      const { pageScroll, inputValue, handleClickSearch, statisData } = toRefs(props)
      return (
        <div class={style.container}>
          <img v-show={pageScroll.value < 200} src={logo} class={style.logo} ></img>
          <div class={pageScroll.value < 200 ? style.searchContent : `${style.searchContent} ${style.searchContentFixed}`}>
            <div class={style.searchView}>
              <div onClick={() => handleClickSearch.value()} class={style.input}>{inputValue.value}</div>
              <div class={style.btn}>佛度一下</div>
            </div>
            <div
              class={style.times}
            >
              已收录{statisData.value?.provide_num}个佛教关键词&nbsp;&nbsp;&nbsp;已提供{statisData.value?.include_num}次搜索
            </div>
          {/*   <div class={style.times}>已提供{statisData.value?.include_num}次搜索</div> */}
          </div>
        </div>
      )
    }
  }
})