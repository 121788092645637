/*
 * @Author: your name
 * @Date: 2021-03-24 21:45:14
 * @LastEditTime: 2021-04-14 21:28:06
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \fodu-h5\src\pages\index\components\List\index.tsx
 */

import { defineComponent, PropType, reactive, toRefs } from "@vue/runtime-core";
import style from  './index.module.less'

import NewItem from './components/NewItem'
import GoodItem from './components/GoodItem'
import { Toast } from "vant";
import { fetchWebSite } from '@/services/index'
import { trim } from '@/utils/utils'

export interface ListProps {
  _id: string;
  highlight?: {
    title?: string[];
    reddescription?: string;
  };
  _source: {
    a_url: string;
    img_url?: string[];
    source: string;
    description: string;
    title: string;
    htmlTitle: string;
    tags: number;
  }
}

export default defineComponent({
  name: 'List',
  components: {
    NewItem,
    GoodItem,
  },
  props: {
    list: {
      type: Array as PropType<ListProps[]>,
      required: true
    },
    finished: { type: Boolean, required: true },
    loading: { type: Boolean, required: true },
    handlePageScroll: {
      type: Function as PropType<() => void>,
      required: true
    },
    handleClickListItem: {
      type: Function as PropType<(V: string, T: string) => void>,
      required: true
    },
    name: {
      type: String
    },
    inputValue: {
      type: String,
      required: true,
    }
  },
  setup(props, { emit }){
    const { list, inputValue, loading, finished, handlePageScroll, handleClickListItem } = toRefs(props)

    const handleClickItem = (url: string, title: string) => {
      handleClickListItem.value(url, title)
    }

    return () => (
      <van-list
        loading={loading.value}
        finished={finished.value}
        immediateCheck={false}
        offset="50"
        finished-text="没有更多了"
        onLoad={handlePageScroll.value}
        class={style.list}
      >
        <a id='links' href="#" style='display:none;'></a>
        {
          list.value.map(item => {
            const { _id, highlight, _source: { a_url, title, tags }} = item
            return (
               <div class={style.item} key={_id} onClick={() => {
                  fetchWebSite({
                    keyword: trim(inputValue.value),
                    link: _id,
                    tags
                  })
                  if (tags === 2) {
                    handleClickItem(a_url, title)
                  } else {
                    window.location.href = `${a_url}`
                  }
                }}>
                  { (tags === 1 || tags === 2 || tags === 3) &&
                    <NewItem
                      contentItem={item._source}
                      highlight={highlight?.title ? highlight?.title[0] : ''}
                      reddescription={highlight?.reddescription ? highlight?.reddescription : ''}
                    />
                  }
                  { (tags === 4) &&
                    <GoodItem
                      highlight={highlight?.title ? highlight?.title[0] : ''}
                      contentItem={item._source}
                      inputValue={inputValue.value}
                      link={_id}
                    />
                  }
              </div>
            )
          })
        }
      </van-list>
    )
  }
})