/*
 * @Author: your name
 * @Date: 2021-04-06 21:28:19
 * @LastEditTime: 2021-04-06 21:57:44
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \fodu-h5\src\pages\index\components\IFrame\index.tsx
 */
import { defineComponent, toRefs, onMounted, PropType } from "@vue/runtime-core";
import style from  './index.module.less'

export default defineComponent({
  props: {
    iframeUrl: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const { iframeUrl } = toRefs(props)
    onMounted(() => {
    })
    return () => {
      return (
        <div class={style.container}>
          <iframe class={style.iframe} src={iframeUrl.value} />
        </div>
      )
    }
  }
})