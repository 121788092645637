/*
 * @Author: your name
 * @Date: 2021-04-12 21:22:18
 * @LastEditTime: 2021-04-14 21:16:04
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \fodu-h5\src\pages\index\components\List\components\GoodItem\index.tsx
 */
import { defineComponent, PropType, toRefs } from "@vue/runtime-core";
import { fetchWebSite } from '@/services/index'
import { trim } from '@/utils/utils'

import style from './index.module.less'

interface GoodItem {
  brief: string;
  img: string;
  link: string;
  name: string;
  price: string;
}

interface ContentItem {
  a_url: string;
  goods?: GoodItem[];
  htmlTitle?: string;
  img_url?: string[];
  source: string;
  description: string;
  tags: number;
  title: string;
}

export default defineComponent({
  props: {
    contentItem: {
      type: Object as PropType<ContentItem>,
      required: true
    },
    highlight: {
      type: String,
      required: true
    },
    inputValue: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    }
  },
  setup(props) {
    const { contentItem, highlight, inputValue, link } = toRefs(props)
    const { img_url, source, description, htmlTitle, goods, tags } = contentItem.value
    return () => {
      return (
        <>
          {
            (img_url && img_url?.length >= 1) ?
            <div class={style.oneImgItem}>
              <div class={style.title} v-html={highlight.value ? highlight.value : htmlTitle}></div>
              <div class={style.content}>
                <img v-lazy={img_url[0]} class={style.image} />
                <div class={style.summary}>{description}</div>
              </div>
              <div class={style.imgList}>
                {
                  goods && goods.map(item => {
                    return (
                      <div class={style.imgItem} onClick={e => {
                        e.stopPropagation();
                        window.location.href = `${item.link}`
                      }}>
                        <img v-lazy={item.img} class={style.img} />
                        <div class={style.name}>{item.name}</div>
                        <div class={style.brief}>{item.brief}</div>
                        <div class={style.price}>{item.price}</div>
                      </div>
                    )
                  })
                }
              </div>
              <div class={style.adValue}>
                <div class={style.source}>{source}</div>
                <div class={style.ad}>
                  <svg class={`${style.adIcon} icon`} aria-hidden="true">
                    <use xlinkHref="#iconguanggao___"></use>
                  </svg>
                </div>
                <div class={style.ad}>
                  <svg class={`${style.adIcon} icon`} aria-hidden="true">
                    <use xlinkHref="#iconbaozhang"></use>
                  </svg>
                </div>
              </div>
            </div> :
            <div class={style.moreImgItem}>
              <div class={style.title} v-html={highlight.value ? highlight.value : htmlTitle}></div>
              <div class={style.value}>{description}</div>
              {
                goods && <div class={style.imgList}>
                  {
                    goods && goods.map(item => {
                      return (
                        <div class={style.imgItem} onClick={e => {
                          e.stopPropagation();
                          fetchWebSite({
                            keyword: trim(inputValue.value),
                            link: link.value,
                            tags
                          })
                          window.location.href = `${item.link}`
                        }}>
                          <img v-lazy={item.img} class={style.img} />
                          <div class={style.name}>{item.name}</div>
                          <div class={style.brief}>{item.brief}</div>
                          <div class={style.price}>{item.price}</div>
                        </div>
                      )
                    })
                  }
                </div>
              }
              <div class={style.adValue}>
                <div class={style.source}>{source}</div>
                <div class={style.ad}>
                  <svg class={`${style.adIcon} icon`} aria-hidden="true">
                    <use xlinkHref="#iconguanggao___"></use>
                  </svg>
                </div>
                <div class={style.ad}>
                  <svg class={`${style.adIcon} icon`} aria-hidden="true">
                    <use xlinkHref="#iconbaozhang"></use>
                  </svg>
                </div>
              </div>
            </div>
          }
        </>
      )
    }
  }
})
