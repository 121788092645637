import { createStore } from 'vuex'
import state from './state'
interface UserInfo {
  account?: string;
  header?: string;
  m_id?: string;
  nickname?: string;
  phone?: string;
}
export interface StateProps {
  userInfo: UserInfo;
  pageScroll: number,
  inputSearchValue: string
}


export default createStore<StateProps>({
  state,
  getters: {
    getUserInfo(state) {
      return state.userInfo
    }
  },
  mutations: {
    changeUserInfo(state, payload) {
      state.userInfo = payload.userInfo
      try {
        localStorage.setItem("userInfo", JSON.stringify(payload.userInfo));
      } catch (e) {}
    },
    // 退出登录。删除个人信息
    removeUserInfo(state) {
      state.userInfo = {};
      localStorage.removeItem("userInfo");
    },
    openModel(state, payload) {
      state.pageScroll = payload.pageScroll;
      document.body.classList.add('model-open');
      document.body.style.top = -state.pageScroll + 'px';
    },
    closeModel(state) {
      document.body.classList.remove('model-open');
      // document.documentElement.scrollTop = state.pageScroll
    },
    changeInputSearchValue(state, payload) {
      state.inputSearchValue = payload.inputSearchValue
      try {
        sessionStorage.setItem("inputSearchValue",JSON.stringify(payload.inputSearchValue));
      } catch (e) {}
    }
  }
})
