/*
 * @Author: your name
 * @Date: 2021-03-21 11:01:57
 * @LastEditTime: 2021-04-07 23:40:48
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \vue3tstemplate\src\main.ts
 */
import 'babel-polyfill';
import { createApp } from 'vue'
import fastClick from 'fastclick'
import {
  Button,
  List,
  Toast,
  Cell,
  CellGroup,
  Lazyload,
  Image as VanImage,
  PullRefresh,
  Form,
  Field,
  Radio,
  RadioGroup,
  Calendar,
} from 'vant'

import './utils/icon'

import App from './App.vue'
import router from './router'
import store from './store'
import './assets/styles/reset.css' // 初始化css
import './assets/styles/border.css' //解决移动端一像素问题
import './assets/styles/iconfont.css'

import { setHtmlFontSize } from './utils/rem'
setHtmlFontSize()

fastClick.attach(document.body);

createApp(App)
  .use(store)
  .use(Button)
  .use(List)
  .use(Toast)
  .use(Cell)
  .use(CellGroup)
  .use(VanImage)
  .use(PullRefresh)
  .use(Form)
  .use(Field)
  .use(Radio)
  .use(RadioGroup)
  .use(Calendar)
  .use(Lazyload)
  .use(router)
  .mount('#app')
