import { defineComponent } from "@vue/runtime-core";

import style from './index.module.less'
import defaultImg from '@/assets/image/components/default.png'

export default defineComponent({
  setup() {
    return () => {
      return (
        <div class={style.container}>
          <img src={defaultImg} class={style.img} />
        </div>
      )
    }
  }
})