/*
 * @Author: your name
 * @Date: 2021-04-03 21:27:59
 * @LastEditTime: 2021-04-03 22:20:06
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \fodu-h5\src\services\index.ts
 */
import { post, get, versionNum } from './service'

export const fetchIndexList = (params: object) => get(`new/${versionNum}/list`, params)

export const fetchSuggest = (params: object) => get(`new/${versionNum}/suggest`, params)

// 获取统计接口
export const fetchWebSite = (params: object) => get(`new/${versionNum}/website`, params)