<!--
 * @Author: your name
 * @Date: 2021-03-21 11:01:57
 * @LastEditTime: 2021-04-13 21:25:23
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \vue3tstemplate\src\App.vue
-->
<template>
  <router-view v-slot="{ Component }">
    <keep-alive include="Index">
      <component class="view" :is="Component" />
    </keep-alive>
  </router-view>
  <!-- <router-view></router-view> -->
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { useRoute } from 'vue-router'
export default defineComponent({
  setup() {
    const route = useRoute()
    return {
      route
    }
  }
})
</script>
<style lang="less">

</style>
