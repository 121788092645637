/*
 * @Author: your name
 * @Date: 2021-05-05 11:15:53
 * @LastEditTime: 2021-05-05 11:15:53
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \fodu-h5\src\components\LoadingImg\index.tsx
 */
import { defineComponent } from "@vue/runtime-core";

import style from './index.module.less'

export default defineComponent({
  setup() {
    return () => {
      return (
        <div class={style.container}>
          <img src="https://img.shanyouchou.com/loading.gif" class={style.img} />
          <span class={style.title}>加载中。。</span>
        </div>
      )
    }
  }
})