/*
 * @Author: your name
 * @Date: 2021-04-03 14:56:11
 * @LastEditTime: 2021-04-03 14:56:12
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \fodu-h5\src\utils\base64Analysis.ts
 */
import { Base64 } from 'js-base64';

export const base64Analysis = (value: string | boolean) => {
  if (typeof value === 'string') {
    let ne = value.substring(10);
    let st = value.substring(0,5);
    return JSON.parse(Base64.decode(st + ne))
  }
}

export const getRandomIntInclusive = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min; //含最大值，含最小值
}

// 给密码添加随机数
export const passwordRandow = (password: string) => {
  let base64Password = Base64.encode(password)
  let pandowNum: any = getRandomIntInclusive(10000, 99999)
  let front = base64Password.substring(0, 5)
  let behind = base64Password.substring(5)
  return front.concat(pandowNum).concat(behind)
}