/*
 * @Author: your name
 * @Date: 2021-04-08 20:59:03
 * @LastEditTime: 2021-05-04 21:18:39
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit=
 * @FilePath: \fodu-h5\src\utils\share.ts
 */
import { shareUser } from '@/services/share'
import { isWXShare } from '@/model/model'
import { Toast } from 'vant'

export const share = () => {
  shareUser({
    url: window.location.href
  }).then(res => {
    if (isWXShare(res)) {
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: res.appid, // 必填，公众号的唯一标识
        timestamp: res.timestamp, // 必填，生成签名的时间戳
        nonceStr: res.nonceStr, // 必填，生成签名的随机串
        signature: res.signature,// 必填，签名
        jsApiList: ['onMenuShareTimeline','onMenuShareAppMessage','hideMenuItems','scanQRCode','updateAppMessageShareData','updateAppMessageShareData'] // 必填，需要使用的JS接口列表
      });
      wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
        wx.updateAppMessageShareData({
          title: '学佛法，上佛度', // 分享标题
          desc: '不用寻它千百度，一点【佛度】全找到', // 分享描述
          link: 'http://test.syusb.com', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: 'https://cdn.fodu.com/logo.jpg', // 分享图标
          success: function () {
            // Toast.success('分享成功')
          }
        })
      });
    }
  })
}