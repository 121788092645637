/*
 * @Author: your name
 * @Date: 2021-04-03 14:51:11
 * @LastEditTime: 2021-04-04 22:39:36
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \fodu-h5\src\services\service.ts
 */
import axios  from 'axios'
import { Toast } from 'vant';
import { base64Analysis, passwordRandow } from '../utils/base64Analysis'
import { config } from './codeConfig'
import { ResData, isString } from '@/model/model'

const baseURL: string  = 'https://api.fodu.com/v1/'
export const versionNum = '1.0'

const instance = axios.create({
  baseURL,
});

export const post = (api: string, data: object): Promise<ResData | boolean | any[]> => {
  let parameter = { parameter : passwordRandow(JSON.stringify(data)) }
  return new Promise((resolve) => {
    instance.post(api, parameter).then((res) => {
      const { code, result } = res.data
      if (code === 200 || code === 4010) {
        if (isString(result)) {
          resolve(base64Analysis(result))
        } else {
          resolve(result)
        }
      } else {
        Toast.fail(config[code])
      }
    }).catch(res => {
      Toast.fail('服务器异常')
    })
  });
}

export const get = (api: string, params: object): Promise<ResData | boolean | any[]> => {
  let parameter = passwordRandow(JSON.stringify(params))
  return new Promise((resolve) => {
    instance.get(api, {params: {parameter}}).then((res) => {
      const { code, result } = res.data
      if (code === 200) {
        if (isString(result)) {
          resolve(base64Analysis(result))
        } else {
          resolve(result)
        }
      } else {
        Toast.fail(config[code])
      }
    }).catch(res => {
      Toast.fail('服务器异常')
    })
  });
}
