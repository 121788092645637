import { defineComponent } from "@vue/runtime-core";

import style from './index.module.less'

export default defineComponent({
  setup() {

    const handleToTop = () => {
      // document.documentElement.scrollTop = 0
      var timer = setInterval(() => {
        let osTop = document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
        if(osTop === 0){
          clearInterval(timer);
        }
      },30)
    }

    return () => {
      return (
        <div class={style.container}>
          <svg class={`${style.toTop} icon`} aria-hidden="true" onClick={handleToTop}>
            <use xlinkHref="#iconfanhuidingbu"></use>
          </svg>
        </div>
      )
    }
  }
})